<template>
  <div
    class="ProgressIndicator inline-flex items-center justify-center overflow-hidden rounded-full relative"
  >
    <svg
      :style="{ width: `${radius * 2 + 4}px`, height: `${radius * 2 + 4}px` }"
    >
      <circle
        class="text-gray-300"
        stroke-width="4"
        stroke="currentColor"
        fill="transparent"
        :r="radius"
        :cx="radius + 2"
        :cy="radius + 2"
        :transform="`rotate(-90) translate(-${(radius + 2) * 2} 0)`"
      />
      <circle
        class="text-primary"
        stroke-width="4"
        :stroke-dasharray="circumference"
        :stroke-dashoffset="circumference - percent * circumference"
        stroke-linecap="round"
        stroke="currentColor"
        fill="transparent"
        :r="radius"
        :cx="radius + 2"
        :cy="radius + 2"
        :transform="`rotate(-90) translate(-${(radius + 2) * 2} 0)`"
      />
    </svg>
    <span class="absolute font-bold text-primary">
      {{
        displayPercentage ? `${percent * 100}%` : `${currentValue}/${maxValue}`
      }}
    </span>
  </div>
</template>

<script>
export default {
  name: "ProgressIndicator",
  props: {
    currentValue: {
      type: Number,
      required: true,
    },
    maxValue: {
      type: Number,
      required: true,
    },
    displayPercentage: {
      type: Boolean,
      default: false,
    },
    radius: {
      type: Number,
      default: 25,
    },
  },
  computed: {
    percent() {
      return this.currentValue / this.maxValue;
    },
    circumference() {
      return this.radius * 2 * Math.PI;
    },
  },
};
</script>

<style lang="scss" scoped></style>
